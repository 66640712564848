export default [
  {
    title: 'Dashboard',
    icon: 'HomeIcon',
    tagVariant: 'light-warning',
    route: 'dashboard',
    business: true,
  },
  {
    title: 'Profile',
    route: 'profile',
    icon: 'UserIcon',
    business: true,
  },
  {
    title: 'Subscription',
    route: 'subscription',
    icon: 'BoxIcon',
    business: true,
  },
  {
    title: 'Invoice',
    route: 'invoice',
    icon: 'FileTextIcon',
    business: true,
  },
  {
    title: 'Payment',
    route: 'payment',
    icon: 'CreditCardIcon',
    business: true,
  },
  {
    title: 'NTivi',
    route: 'ntivi',
    icon: 'TvIcon',
    business: true,
  },
  {
    title: 'NDrive',
    route: 'ndrive',
    icon: 'HardDriveIcon',
    business: true,
  },
  {
    title: 'Products',
    route: 'my-products',
    icon: 'BoxIcon',
    business: false,
  },
  {
    title: 'Ticket',
    route: 'open-ticket',
    icon: 'MessageSquareIcon',
    business: true,
  },
  {
    title: 'FAQ',
    route: 'faq',
    icon: 'FileIcon',
    business: true,
  },
  // {
  //   title: 'Products',
  //   route: 'products',
  //   icon: 'BoxIcon',
  // },
]
